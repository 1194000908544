import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Header.module.css';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  logoImage: string;
}

const Header: React.FC<HeaderProps> = ({ logoImage }) => {
  const { t } = useTranslation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [sliderStyle, setSliderStyle] = useState({});
  const location = useLocation();
  const navRef = useRef<HTMLUListElement>(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    if (navRef.current) {
      const activeLink = navRef.current.querySelector(
        `a[href='${location.pathname}']`
      );
      if (activeLink) {
        const linkRect = activeLink.getBoundingClientRect();
        const navRect = navRef.current.getBoundingClientRect();
        setSliderStyle({
          width: `${linkRect.width}px`,
          left: `${linkRect.left - navRect.left}px`,
        });
      }
    }
  }, [location]);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">
          <span className={styles.logoText}>
            <img
              src={`/${logoImage}`}
              alt="MK Logo"
              className={styles.logoImage}
            />
          </span>
        </Link>
      </div>
      <button className={styles.navToggle} onClick={toggleNav}>
        <span className={styles.navIcon}></span>
      </button>
      <nav className={`${styles.nav} ${isNavOpen ? styles.navOpen : ''}`}>
        <ul ref={navRef}>
          <li>
            <Link
              to="/"
              className={location.pathname === '/' ? styles.active : ''}
            >
              {t('category.home')}
            </Link>
          </li>
          <li>
            <Link
              to="/portfolio"
              className={
                location.pathname === '/portfolio' ? styles.active : ''
              }
            >
              {t('category.portfolio')}
            </Link>
          </li>
          <li>
            <Link
              to="/cv"
              className={location.pathname === '/cv' ? styles.active : ''}
            >
              {t('category.cv')}
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={location.pathname === '/contact' ? styles.active : ''}
            >
              {t('category.contact')}
            </Link>
          </li>
        </ul>
        <div className={styles.slider} style={sliderStyle}></div>
      </nav>
    </header>
  );
};

export default Header;
