import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const languageOptions = [
    { key: 'en', text: 'English', value: 'en', flag: 'uk' },
    { key: 'fr', text: 'Français', value: 'fr', flag: 'fr' },
  ];

  const changeLanguage = (
    _: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    if (typeof data.value === 'string') {
      i18n.changeLanguage(data.value);
    }
  };

  return (
    <Dropdown
      labeled
      options={languageOptions}
      onChange={changeLanguage}
      defaultValue={currentLanguage}
    />
  );
};

export default LanguageSelector;
