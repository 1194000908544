import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CenteredLayout from './components/CenteredLayout/CenteredLayout';
import styles from './App.module.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

const Home = lazy(() => import('./components/Home/Home'));
const Portfolio = lazy(() => import('./components/Portfolio/Portfolio'));
const CV = lazy(() => import('./components/CV/CV'));
const Contact = lazy(() => import('./components/Contact/Contact'));
const Loading = () => <div>Loading...</div>;

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdmrCwqAAAAAKQ2gqaxwgU_HukxTXHV_gl2mb2P"
      language={currentLanguage}
    >
      <Suspense fallback={<Loading />}>
        <Router>
          <div className={styles.pageContainer}>
            <Header logoImage="logo512.png" />
            <main className={styles.contentWrap}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <CenteredLayout>
                      <Home />
                    </CenteredLayout>
                  }
                />
                <Route
                  path="/portfolio"
                  element={
                    <CenteredLayout>
                      <Portfolio />
                    </CenteredLayout>
                  }
                />
                <Route
                  path="/cv"
                  element={
                    <CenteredLayout>
                      <CV />
                    </CenteredLayout>
                  }
                />
                <Route
                  path="/contact"
                  element={
                    <CenteredLayout>
                      <Contact />
                    </CenteredLayout>
                  }
                />
              </Routes>
            </main>
            <Footer />
          </div>
        </Router>
      </Suspense>
    </GoogleReCaptchaProvider>
  );
};

export default App;
