import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import { Icon } from 'semantic-ui-react';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.languageSelectorWrapper}>
          <LanguageSelector />
        </div>
        <nav className={styles.footerNav}>
          <Link to="/">{t('category.home')}</Link>
          <Link to="/portfolio">{t('category.portfolio')}</Link>
          <Link to="/cv">{t('category.cv')}</Link>
          <Link to="/contact">{t('category.contact')}</Link>
        </nav>
        <div className={styles.socialLinks}>
          <a
            href="https://www.linkedin.com/in/minh-kha-nguyen/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="linkedin" size="large"></Icon>
          </a>
          <a
            href="https://github.com/Nguyen-Minh-Kha"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="github" size="large"></Icon>
          </a>
          <a
            href="https://discordapp.com/users/936539240219688981"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="discord" size="large"></Icon>
          </a>
        </div>
        <p className={styles.copyright}>
          &copy; {currentYear} {t('footer.name')}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
